import React from 'react';
import styled, { withTheme } from 'styled-components';
import { animated } from 'react-spring';
import { Text } from 'system';
import { headingsCss } from 'theme/typography';

const SAText = styled(animated(Text))(
  ...headingsCss,
  `& p {
    margin-bottom:1.5em;
    }
    & > *:last-child{
        margin-bottom:0
    }`
);

function Content({ theme, style = null, css = null, ...rest }) {
  return <SAText style={style} css={css} {...rest} />;
}

export default withTheme(Content);
