import React from 'react';
import { withTheme } from 'styled-components';
import { withParallaxProvider, ParallaxChild } from 'hooks/useParallax';
import { Section, Container, Row, Column } from 'system/layout';
import { Flex } from 'system/animated';
import Content from 'components/Content';
import PlusLink from 'components/PlusLink';
import LineHeading from 'components/LineHeading';
import { resolveLinkByOriginalId } from 'utils/resolveLink';
import { withGlobals } from 'globals/index';
import css from '@styled-system/css';

function HomeAboutUsCalloutBlock({
  theme,
  scrollTop,
  inView,
  ref,
  data,
  locale,
}) {
  const {
    aboutCallout,
    aboutParagraphNode,
    aboutCtaLabel,
    aboutCtaLink,
  } = data;
  return (
    <Section ref={ref} pt={0}>
      <Container>
        <Row>
          <Column width={[3 / 4, 3 / 4, 5 / 6]} ml={[`25%`, `25%`, `16.6667%`]}>
            <LineHeading
              direction={`rtl`}
              label={aboutCallout}
              inView={inView}
              offsetY={0.05}
            />
          </Column>
          <ParallaxChild
            as={Column}
            offsetY={0.1}
            width={[1, 1, 3 / 4]}
            ml={[0, 0, 0, `25%`]}
          >
            <Content
              mt={44}
              dangerouslySetInnerHTML={{
                __html: aboutParagraphNode.childMarkdownRemark.html,
              }}
              css={css({
                h2: {
                  fontSize: [21, 24, 28, 32, 38],
                  lineHeight: `1.15789473684`,
                },
              })}
            />
          </ParallaxChild>
        </Row>
        <ParallaxChild
          as={Flex}
          offsetY={0.15}
          mt={[30, 50, 75, 80, 90]}
          width={1}
          justifyContent={`flex-end`}
          zIndex={1}
        >
          <PlusLink
            label={aboutCtaLabel}
            link={resolveLinkByOriginalId(aboutCtaLink.originalId, locale)}
          />
        </ParallaxChild>
      </Container>
    </Section>
  );
}

export default withGlobals(
  withTheme(withParallaxProvider(HomeAboutUsCalloutBlock))
);
