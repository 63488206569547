import React, {
  useState,
  useMemo,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import { withTheme } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { ParallaxChild, withParallaxProvider } from 'hooks/useParallax';
import { Text, Absolute, Box } from 'system';
import * as A from 'system/animated';
import { Section, Column, Container, Row } from 'system/layout';
import useWindowResize from 'hooks/useWindowResize';
import Img from 'components/Img/index';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import css from '@styled-system/css';

const CUT_HEIGHT = [130, 230, 400, 500];

function BG({ theme, scrollTop }) {
  const pts = scrollTop.to(st => {
    const t = Math.abs(Math.round(st * 0.1));
    const pos = Math.min(t, 100);
    return `100,${pos} 100,100 0,100`;
  });

  const { documentHeight } = useWindowResize();

  return (
    <A.Absolute
      zIndex={0}
      top={[70, 70, 100, 125]}
      left={0}
      width={1}
      height={documentHeight > 0 ? documentHeight : 5000}
      display={`flex`}
      flexDirection={`column`}
    >
      <Box width={1} height={CUT_HEIGHT}>
        <Box
          as={`svg`}
          width={1}
          height={`100%`}
          viewBox={`0 0 100 100`}
          preserveAspectRatio={`none`}
        >
          <animated.polygon fill={theme.colors.grey} points={pts} />
        </Box>
      </Box>
      <Box width={1} flex={1} bg={theme.colors.grey} />
    </A.Absolute>
  );
}

function HeroAnimation({
  idx = 0,
  theme,
  data,
  first = false,
  activeState = `start`,
  setPageReady = null,
  setLogoColor,
  zIndex,
  debug = false,
}) {
  const { front, back, pts } = data;
  // const clipPathId = `hero-${idx}-clippath`;
  //spring
  const loadedImages = useRef(0);
  const [ready, setReady] = useState(false);
  const [state, setState] = useState(
    activeState === `in` && ready ? `in` : `start`
  );

  const onImageLoaded = useCallback(() => {
    loadedImages.current++;
    if (loadedImages.current === 2) {
      setReady(true);
    }
  }, []);

  const [clipSpring, setClipSpring] = useSpring(() => ({
    config: { mass: 1, tension: 150, friction: 17, clamp: true },
    delay: 0,
    immediate: true,
    tb: [100, 100],
  }));

  const [ptsSpring, setPtsSpring] = useSpring(() => ({
    config: { mass: 1, tension: 120, friction: 20, clamp: true },
    delay: 0,
    immediate: true,
    pts: pts.start,
  }));

  useEffect(() => {
    setPtsSpring({
      immediate: true,
      pts: pts.start,
      onRest: null,
    });
    setClipSpring({ immediate: true, tb: [100, 100] });
  }, [pts.start, setClipSpring, setPtsSpring]);

  useEffect(() => {
    if (ready && activeState === `in`) {
      setState(`in`);
      if (setPageReady) {
        setLogoColor(theme.colors.blue);
        setPageReady(true);
      }
      setPtsSpring({
        immediate: false,
        delay: first ? 500 : 900,
        onRest: null,
        pts: pts.in,
      });
      setClipSpring({
        immediate: false,
        delay: first ? 200 : 600,
        tb: [0, 100],
      });
    } else if (activeState === `out`) {
      setState(`out`);
      setPtsSpring({
        immediate: false,
        delay: 0,
        pts: pts.out,
        onRest: () => {
          setPtsSpring({
            immediate: true,
            delay: 500,
            pts: pts.start,
            onRest: null,
          });
          setClipSpring({ immediate: true, delay: 1000, tb: [100, 100] });
        },
      });
    }
  }, [
    ready,
    activeState,
    state,
    first,
    pts.in,
    pts.out,
    pts.start,
    setClipSpring,
    setPtsSpring,
    setLogoColor,
    setPageReady,
    theme.colors.blue,
  ]);

  return (
    <A.Absolute
      top={0}
      left={0}
      width={1}
      height={`100%`}
      style={{
        zIndex,
        // clipPath: `polygon(0% 59.090066662921174%, 100% 59.090066662921174%, 100% 100%, 0 100%)`,
        clipPath: clipSpring.tb.to(
          (t, b) => `polygon(0% ${t}%, 100% ${t}%, 100% ${b}%, 0 ${b}%)`
        ),
        WebkitClipPath: clipSpring.tb.to(
          (t, b) => `polygon(0% ${t}%, 100% ${t}%, 100% ${b}%, 0 ${b}%)`
        ),
        MSClipPath: clipSpring.tb.to(
          (t, b) => `polygon(0% ${t}%, 100% ${t}%, 100% ${b}%, 0 ${b}%)`
        ),
      }}
    >
      <Img
        {...back}
        onLoad={onImageLoaded}
        critical={idx === 0}
        style={{ backfaceVisibility: `hidden` }}
      />
      <A.Absolute
        as={`figure`}
        top={`-50%`}
        left={`-50%`}
        width={`200%`}
        height={`200%`}
        display={`block`}
        style={{ backfaceVisibility: `hidden` }}
      >
        <Box
          as={`svg`}
          width={`100%`}
          height={`100%`}
          viewBox={`0 0 100 100`}
          preserveAspectRatio={`none`}
          display={`block`}
          style={{
            opacity: 0.65,
            backfaceVisibility: `hidden`,
          }}
        >
          <animated.polygon
            fill={theme.colors.yellow}
            points={ptsSpring.pts.to(pts => pts)}
          />
        </Box>
      </A.Absolute>
      <Absolute
        top={0}
        left={0}
        width={1}
        height={`100%`}
        style={{ backfaceVisibility: `hidden` }}
      >
        <Img {...front} onLoad={onImageLoaded} critical={idx === 0} />
      </Absolute>
    </A.Absolute>
  );
}

function HomeHeroBlock({
  theme,
  ref,
  parallax,
  images,
  setPageReady,
  setLogoColor,
  heroHeading,
}) {
  const imageByName = useMemo(() => {
    let ref = {};
    images.forEach(image => {
      ref[image.name] = { ...image.childImageSharp, alt: image.alt };
    });
    return ref;
  }, [images]);

  const heroImages = useMemo(() => {
    return [
      {
        front: imageByName[`streetlight-front`],
        back: imageByName[`streetlight-back`],
        pts: {
          start: `23,80 100,80 100,80 100,80 40,80`,
          in: `23,80 70,0 70,0 100,50 40,80`,
          out: `23,80 0,0 70,0 100,100 40,80`,
        },
      },
      {
        front: imageByName[`to-front`],
        back: imageByName[`to-back`],
        pts: {
          start: `93,80 93,80 0,100 0,100 0,100`,
          in: `93,80 93,80 0,-10 0,-10 0,45`,
          out: `93,80 93,80 100,-10 0,-10 0,100`,
        },
      },
      {
        front: imageByName[`qc-front`],
        back: imageByName[`qc-back`],
        pts: {
          start: `0,100 0,100 0,100 100,95 100,100`,
          in: `0,100 0,-30 0,-30 100,95 100,100`,
          out: `0,100 0,-30 100,-30 100,95 100,100`,
        },
      },
      {
        front: imageByName[`van-front`],
        back: imageByName[`van-back`],
        pts: {
          start: `0,100 80,100 80,100 100,100 0,100`,
          in: `0,78 80,10 80,10 100,50 0,78`,
          out: `0,78 0,10 80,10 100,100 0,78`,
        },
      },
      {
        front: imageByName[`mtl-front`],
        back: imageByName[`mtl-back`],
        pts: {
          start: `100,85 100,85 0,100 0,100 0,100`,
          in: `100,85 100,85 0,-10 0,-10 0,54`,
          out: `100,85 100,85 100,-10 0,-10 0,100`,
        },
      },
      {
        front: imageByName[`nyc-front`],
        back: imageByName[`nyc-back`],
        pts: {
          start: `23,78 23,78 100,100 100,100 100,100`,
          in: `23,75 23,78 100,60 100,-20 100,-20`,
          out: `23,75 23,78 100,100 100,-20 0,-20`,
        },
      },
    ];
  }, [imageByName]);

  const isFirst = useRef(true);
  const activeTimeout = useRef(0);
  const [[activeIndex, renderableIndex], setActiveIndex] = useState([0, 1]);

  const next = useCallback(() => {
    let newActiveIndex = activeIndex + 1;
    if (newActiveIndex >= heroImages.length) {
      newActiveIndex = 0;
    }
    isFirst.current = false;
    setActiveIndex([
      newActiveIndex,
      newActiveIndex + 1 > renderableIndex
        ? newActiveIndex + 1
        : renderableIndex,
    ]);
  }, [setActiveIndex, activeIndex, heroImages.length, renderableIndex]);

  useEffect(() => {
    return () => {
      clearTimeout(activeTimeout.current);
    };
  }, []);

  const inViewForPauseRef = useRef();
  const [inViewForPause] = useIntersectionObserver(ref, {
    rootMargin: `10% 0% 10% 0%`,
  });

  useEffect(() => {
    if (inViewForPause) {
      activeTimeout.current = setTimeout(() => {
        next();
      }, 6000);
    } else {
      clearTimeout(activeTimeout.current);
    }
  }, [inViewForPause, activeIndex, next]);

  return (
    <>
      <Section
        ref={ref}
        minHeight={[350, 550, 750]}
        pt={[110, 125, 140, 155, 170]}
        position={`relative`}
        id={'top'} //used to scroll to top of page when user clicks on 'Jungle' logo
      >
        <BG theme={theme} scrollTop={parallax.scrollTop} />
        <Container ref={inViewForPauseRef}>
          <Row
            style={{ overflow: `hidden` }}
            width={[`100vw`, `100vw`, 1]}
            ml={[`50%`, `50%`, -3, -4, -5]}
            mr={[0, 0, -3, -4, -5]}
            justifyContent={`center`}
            transform={[
              `translate3d(-50vw,0,0)`,
              `translate3d(-50vw,0,0)`,
              `none`,
            ]}
          >
            <ParallaxChild
              as={Column}
              initialY={2000}
              offsetY={0.15}
              width={[1, 1, 11 / 12, 8 / 12, 8.5 / 12, 11 / 12]}
              mt={[110, 110, 80]}
              px={[0, 0, 6]}
            >
              <Box
                width={1}
                pt={`${(1 / heroImages[0].front.fluid.aspectRatio) * 100}%`}
                style={{ overflow: `hidden` }}
              >
                {heroImages.map((hi, idx) =>
                  idx <= renderableIndex ? (
                    <HeroAnimation
                      key={`heroanim-${idx}`}
                      zIndex={activeIndex === idx ? 2 : 1}
                      first={isFirst.current}
                      idx={idx}
                      theme={theme}
                      activeState={activeIndex === idx ? `in` : `out`}
                      data={hi}
                      setPageReady={idx === 0 ? setPageReady : null}
                      setLogoColor={setLogoColor}
                    />
                  ) : null
                )}
              </Box>
            </ParallaxChild>
          </Row>
          <ParallaxChild
            as={A.Absolute}
            offsetY={[0.05, 0.05, 0.075]}
            top={[10, 10, 0]}
            left={0}
            width={1}
          >
            <Text
              as={`h1`}
              color={theme.colors.blue}
              fontSize={[30, 36, 42, 54, 68]}
              mb={20}
              lineHeight={1}
              fontWeight={`bold`}
              fontFamily={theme.fonts.headings}
              dangerouslySetInnerHTML={{
                __html: heroHeading.replace(/<p[^>]*>/g, ''),
              }}
              css={`
                & strong {
                  ${css({
                    display: [`block`, `block`, `inline`],
                    '-webkit-text-stroke-width': [`1.5px`, `1.5px`, `2px`],
                  })}
                  color: white;
                  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
                  -webkit-text-stroke-color: ${theme.colors.blue};
                }
              `}
            />
          </ParallaxChild>
        </Container>
      </Section>
    </>
  );
}

export default withTheme(withParallaxProvider(HomeHeroBlock));
