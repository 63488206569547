import CaseStudyListingBlock from 'blocks/CaseStudyListingBlock';
import HomeAboutUsCalloutBlock from 'blocks/HomeAboutUsCalloutBlock';
import HomeHeroBlock from 'blocks/HomeHeroBlock';
import Footer from 'components/Footer';
import {graphql} from 'gatsby';
import {HelmetDatoCms} from 'gatsby-source-datocms';
import {withGlobals} from 'globals';
import {withParallaxProvider} from 'hooks/useParallax';
import React, {useMemo} from 'react';
import {withTheme} from 'styled-components';
import theme from 'theme';

function HomePage({data, footerData, setPageReady, setLogoColor}) {
	const {page, heroImages} = data;

	const {
		aboutCallout,
		aboutParagraphNode,
		aboutCtaLabel,
		aboutCtaLink,
		workCallout,
		workHeading,
		featuredCaseStudies,
		heroHeading,
	} = page;

	const heroImagesWithAltTags = useMemo(() => {
		return heroImages.edges.map(edge => {
			const name = edge.node.name;
			let alt = ``;
			switch (name) {
				case 'to-front':
					alt = 'Toronto - foreground';
					break;
				case 'to-back':
					alt = 'Toronto - background';
					break;
				case 'van-front':
					alt = 'Vancouver - foreground';
					break;
				case 'van-back':
					alt = 'Vancouver - background';
					break;
				case 'mtl-front':
					alt = 'Montréal - foreground';
					break;
				case 'mtl-back':
					alt = 'Montréal - background';
					break;
				case 'qc-front':
					alt = 'Québec - foreground';
					break;
				case 'qc-back':
					alt = 'Québec - background';
					break;
				case 'nyc-front':
					alt = 'New York - foreground';
					break;
				case 'nyc-back':
					alt = 'New York - background';
					break;
				case 'streetlight-front':
					alt = 'Street light - foreground';
					break;
				case 'streetlight-back':
					alt = 'Street light - background';
					break;
				default:
					alt = 'rotating image - home page';
					break;
			}
			return {...edge.node, alt};
		});
	}, [heroImages]);

	return (
		<>
			<HelmetDatoCms seo={data.page.seoMetaTags}/>
			<HomeHeroBlock
				heroHeading={heroHeading}
				images={heroImagesWithAltTags}
				setPageReady={setPageReady}
				setLogoColor={setLogoColor}
				normal={0.15}
			/>
			<HomeAboutUsCalloutBlock
				normal={[0.25, 0.25, 0.15]}
				data={{
					aboutCallout,
					aboutParagraphNode,
					aboutCtaLabel,
					aboutCtaLink,
				}}
			/>
			<CaseStudyListingBlock
				callout={workCallout}
				heading={workHeading}
				cases={featuredCaseStudies.filter(c => !c.hide)}
				showAll={true}
			/>
			<Footer data={footerData}/>
		</>
	);
}

HomePage.bgColor = theme.colors.white;

export const query = graphql`
  query($locale: String!) {
    heroImages: allFile(
      filter: { sourceInstanceName: { eq: "images" }, name: { ne: "icon" } }
    ) {
      edges {
        node {
          id
          name
          childImageSharp {
            fluid(maxWidth: 1228) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    }

    page: datoCmsHomePage(locale: { eq: $locale }) {
      id
      locale
      originalId
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heroHeading
      heroHeadingNode {
        childMarkdownRemark {
          html
        }
      }
      aboutCallout
      aboutParagraphNode {
        childMarkdownRemark {
          html
        }
      }
      aboutCtaLabel
      aboutCtaLink {
        originalId
        title
        url
      }
      workCallout
      workHeading
      featuredCaseStudies {
        id
        hide
        listingTitle
        client {
          name
          logo {
            url
            width
            height
            fluid(
              maxWidth: 200
              imgixParams: { auto: "format,compress", q: 80 }
            ) {
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
        listingImage {
          url
          width
          height
          fluid(
            maxWidth: 760
            imgixParams: { auto: "format,compress", q: 75 }
          ) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
    }
  }
`;

export default withGlobals(withTheme(withParallaxProvider(HomePage)));
